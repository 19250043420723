.bg-bantex {
	background-color: #0E2159;
}

.border-bantex {
	border-color: #0E2159!important;
}

.bg-footer-bantex {
	background-color: #f0f0f0;
}

.text-bantex {
	color: #0E2159;
}

.table-basic-w-status {
	width: 12%;
}

.table-basic-w-created {
	width: 15%;
}

.table-basic-w-field-1 {
	width: 45%;
}

.table-basic-w-id,
.table-basic-w-actions {
	width: 1%;
}

.table-basic-no-rows {
	
}

.container-fluid-form {
	width: 60%;
}

.link-home {
	/*width: 25px;*/
	/*height: 25px;*/
	/*background: url("images/inicio.png") #ff0000 no-repeat center/100%;*/
}

.link-bantex:hover {
	opacity: 0.7;
}

.btn-outline-bantex {
	border-color: #0E2159!important;
	color: #0E2159!important;
}

.btn-outline-bantex:hover {
	background-color: #0E2159!important;
	color: white!important;
}

.btn-check-bantex:checked + .btn-outline-bantex {
	background-color: #0E2159!important;
	color: white!important;
}

.nav-link-bantex {
	color: #0E2159!important;
}

.page-link-bantex {
	color: #0E2159!important;
}

.disabled > .page-link-bantex {
	color: #7d7d7d!important;
}

ul.pagination li.active a {
	background-color: #0E2159!important;
	border-color: #0E2159!important;
	color: #fff!important;
}

.nav-link-bantex.active {
	color: white!important;
	background-color: #0E2159!important;
}

.dropdown .dropdown-menu .dropdown-item:active {
	background-color: #0E2159!important;
}